import { DEFAULT_PATHS } from 'config.js';
import { USER_ROLE } from 'constants.js';
import { lazy } from 'react';

const studentNotifications = {
  notification: lazy(() =>
    import('views/studentNotification/StudentNotificationPage')
  ),
};
const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const studentNotificationsRoute = {
  path: `${appRoot}/notificacoes-estudante`,
  exact: true,
  icon: 'bell',
  label: 'notification.notifications',
  component: studentNotifications.notification,
  protected: true,
  roles: [USER_ROLE.Student],
  to: `${appRoot}/notificacoes`,
};

export default studentNotificationsRoute;
