import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';

const profile = {
  profilePage: lazy(() => import('views/profile/ProfilePage')),
};
const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const profileRoute = {
  path: `${appRoot}/cadastro`,
  exact: true,
  icon: 'user',
  label: 'menu.cadastre',
  component: profile.profilePage,
  protected: true,
  roles: [USER_ROLE.StudentSmelj],
  to: `${appRoot}/cadastro`,
};

export default profileRoute;
