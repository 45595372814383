import { DEFAULT_PATHS } from 'config.js';
import { USER_ROLE } from 'constants.js';
import { lazy } from 'react';

const studentProfile = {
  studentPerformance: lazy(() =>
    import('views/studentView/studentPerformance/StudentPerformancePage')
  ),
};

const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const studentProfileRoute = {
  path: `${appRoot}/desempenho`,
  exact: true,
  icon: 'online-class',
  label: 'commons.scores',
  component: studentProfile.studentPerformance,
  protected: true,
  roles: [USER_ROLE.Student],
  to: `${appRoot}/desempenho`,
};

export default studentProfileRoute;
