const typePtMessages = {
  // evaluation type
  PROOF: 'Prova',
  WORK: 'Trabalho',
  FEEDBACK: 'Parecer',
  RECOVERY: 'Recuperação',
  ANNUAL_RECOVERY: 'Recuperação Anual',

  // student attendance type
  ATTENDANCE: 'Presente',
  ABSENCE: 'Ausente',
  JUSTIFIED_ABSENCE: 'Falta Justificada',

  // education level type
  QUARTER: 'Bimestre',
  TRIMESTER: 'Trimestre',
  SEMESTER: 'Semestre',

  // status
  ACTIVE: 'Ativo',
  SURPLUS: 'Fila de espera',

  // message status
  CONFIRMED: 'Confirmado por',
  DELIVERED: 'Entregue para',
  READ: 'Lido por',

  // performance status
  UPDATED: 'Atualizado',
  NEED_UPDATE: 'Atualização necessária',
  IN_PROCESS: 'Atualização em andamento',

  // reports
  OVERVIEW_MAP: 'Mapa Geral',
  NOTE_SUMMARY: 'Resumo de Notas',
  SCHOOL_DIARY: 'Diário Escolar',
  LESSON_PLAN_FREQUENCY: 'Frequência',
  STUDENT_ENROLLMENT_FREQUENCY: 'Frequência',
  STUDENT_ENROLLMENT_ASSESSMENT: 'Relatório parecer',
  STUDENT_PERFORMANCE: 'Boletim escolar',
};

export default typePtMessages;
