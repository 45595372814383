import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';

const studentEnrollment = {
  studentEnrollment: lazy(() =>
    import('views/studentEnrollment/StudentEnrollmentPage')
  ),
};
const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const studentEnrollmentRoute = {
  path: `${appRoot}/matricula-aluno`,
  exact: true,
  icon: 'grid-1',
  label: 'menu.student-enrollment',
  component: studentEnrollment.studentEnrollment,
  protected: true,
  roles: [USER_ROLE.StudentSmelj],
  to: `${appRoot}/matricula-aluno`,
};

export default studentEnrollmentRoute;
