import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import api from 'services/api';

export const getStudentPerformance = createAsyncThunk(
  'studentPerformance/getStudentPerformance',
  async (_, { getState }) => {
    const {
      menuYear: { selectedYear },
    } = getState();

    try {
      const response = await api.post('grade-performance', {
        academicYearId: selectedYear.id,
      });
      const { data } = await response.data;

      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const studentPerformanceAdapter = createEntityAdapter({});

export const {
  selectAll: selectStudentPerformance,
  selectEntities: selectStudentPerformanceEntities,
} = studentPerformanceAdapter.getSelectors((state) => state.studentPerformance);

const studentPerformanceSlice = createSlice({
  name: 'studentPerformance',
  initialState: studentPerformanceAdapter.getInitialState({
    scoresModal: {
      show: false,
      data: null,
    },
  }),
  reducers: {
    openScoresModal: (state, { payload }) => {
      state.scoresModal = { show: true, data: payload };
    },
    closeScoresModal: (state) => {
      state.scoresModal = { data: null, show: false };
    },
  },
  extraReducers: {
    [getStudentPerformance.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        studentPerformanceAdapter.setAll(state, payload.data);
      }
    },
  },
});

export const { openScoresModal, closeScoresModal } =
  studentPerformanceSlice.actions;

export default studentPerformanceSlice.reducer;
