import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import api from 'services/api';

export const getStudentNotifications = createAsyncThunk(
  'studentNotifications/getStudentNotifications',
  async ({ currentPage, rowsPerPage }, { getState }) => {
    const {
      notifications: { pagination },
      menuYear: { selectedYear },
    } = getState();

    const { data } = await api.get('school-message', {
      params: {
        academicYearId: selectedYear.id,
        currentPage: currentPage ?? pagination.currentPage,
        rowsPerPage: rowsPerPage ?? pagination.rowsPerPage,
      },
    });

    return data;
  }
);

export const getStudentNotificationInfos = createAsyncThunk(
  'studentNotifications/getStudentNotificationInfos',
  async (id) => {
    try {
      const { data } = await api.get(`school-message/${id}`);
      return { data, success: true };
    } catch (error) {
      return { data: error, success: false };
    }
  }
);

const studentNotificationsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
});

export const {
  selectAll: selectStudentNotifications,
  selectEntities: selectStudentNotificationsEntities,
  selectById: selectStudentNotificationById,
} = studentNotificationsAdapter.getSelectors(
  (state) => state.studentNotifications
);

const notificationsSlice = createSlice({
  name: 'studentNotifications',
  initialState: studentNotificationsAdapter.getInitialState({
    pagination: {
      currentPage: 1,
      nextPage: null,
      previousPage: null,
      rowsPerPage: 20,
      totalPages: 1,
      totalRowCount: 0,
    },
    selectedNotification: null,
    notificationInfosModal: {
      data: null,
      show: false,
    },
  }),
  reducers: {
    openStudentNotificationInfosModal: (state, { payload }) => {
      state.notificationInfosModal = { show: true, data: payload };
    },
    closeStudentNotificationInfosModal: (state) => {
      state.notificationInfosModal = { data: null, show: false };
    },
  },
  extraReducers: {
    [getStudentNotifications.fulfilled]: (state, { payload }) => {
      studentNotificationsAdapter.setAll(state, payload.data);
      state.pagination = {
        currentPage: payload.currentPage,
        nextPage: payload.nextPage,
        previousPage: payload.previousPage,
        rowsPerPage: payload.rowsPerPage,
        totalPages: payload.totalPages,
        totalRowCount: payload.totalRowCount,
      };
    },

    [getStudentNotificationInfos.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        state.selectedNotification = payload.data;
      }
    },
  },
});

export const {
  openStudentNotificationInfosModal,
  closeStudentNotificationInfosModal,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
