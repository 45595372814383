import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';

const dashboards = {
  school: lazy(() => import('views/dashboards/SchoolDashboard')),
};

const dashRoute = {
  path: DEFAULT_PATHS.APP,
  exact: true,
  icon: 'home-garage',
  label: 'menu.dashboards',
  component: dashboards.school,
  protected: true,
  roles: [
    USER_ROLE.Admin,
    USER_ROLE.Instructor,
    USER_ROLE.StudentSmelj,
    USER_ROLE.Student,
  ],
};

export default dashRoute;
